.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #09d3ac;
  }
  .demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 100%;
  }
  .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
  .textEdit{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .styles_Editext__main_container__2Y-IL{
    width: 100%;
}

.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}


.editBtn{
  background: transparent;
  border: aliceblue;
}